import React from "react";


/* <========================== Load Images ==============================> */
import charoThumbnail from "./../images/covers/charo.png";
import turkboxThumbnail from "./../images/covers/turkbox.png";
import digitalAsiaThumbnail from "./../images/covers/digital-asia.png";

import turkbox1 from "./../images/covers/longforms/turkbox1.png";
import turkbox2 from "./../images/covers/longforms/turkbox2.jpg";

import charo1 from "./../images/covers/longforms/charo1.jpg";
import charo2 from "./../images/covers/longforms/charo2.png";

import digitalAsia1 from "./../images/covers/longforms/digital-asia1.png";
import digitalAsia2 from "./../images/covers/longforms/digital-asia2.png";


const longforms = [
    {
        "name": "Turkbox",
        "description": "A platform that allows reader to pay for online news by helping train machine learning models",
        "thumbnail": turkboxThumbnail,
        "link": "/blog/turkbox",
        "post": {
            "title": "Turkbox",
            "coverUrl": turkbox1,
            "type": `Entrepreneurship, Technology, Journalism`,
            "timeline": `August 2018 - Present`,
            "challenge": `A platform using which publishers can embed widgets containing data annotation, that readers complete to access articles. These tasks help data companies train AI, and these companies pay publishers for each task performed.`,
            "solution": "A platform using which publishers can embed widgets containing data annotation, that readers complete to access articles. These tasks help data companies train AI, and these companies pay publishers for each task performed.",
            "awards": (<>
                <ul>
                    <li>
                        Winner of Google News Initiatiive APAC Innovation Challenge
                    </li>
                    <li>
                        4th Place Overall at Cozad New Venture Competition
                    </li>
                    <li>
                        Meyer Capel Prize at Cozad New Venture Competition
                    </li>
                    <li>
                        Huawei Innovation Award at Cozad New Venture Competition
                    </li>
                </ul>
            </>),
            "links": (<>
                <ul>
                    <li><a href="https://www.turkbox.io">Website</a></li>
                    <li><a href="https://www.blog.google/around-the-globe/google-asia/here-are-winners-gni-innovation-challenge-asia-pacific/">Google Blog Post</a></li>
                    <li><a href="https://tec.illinois.edu/news/articles-from-tec/30694">Cozad Results</a></li>
                </ul>
            </>),
            "body": (<>
                <p>We started working on Turkbox at the end of Summer 2018, with a goal to make news and information on the internet free. As users of the internet, we‘re fundamentally frustrated with today’s online news media landscape. Online news publications are increasingly adopting the paywall business model, where readers get a certain number of free articles after which they have to buy a subscription to continue reading. This is a problem for readers and the internet in general, as information is no longer free.</p>
                <p>Turkbox is a content monetization platform that allows publications to embed CAPTCHA-like data classification or annotation tasks, which readers complete to get access to articles. This gives readers a more “accessible” pay-as-you-go option, since even though articles remain behind a barrier of sorts, readers are more inclined to complete a straightforward data classification task than they are to buy a subscription to a news publication website they may or may not use in the future.</p>
                <img src={turkbox2} alt={`Winning prizes at the Cozad 2018 competition at UIUC`} />
                <p>With funding from the Google News Initiative, we plan to run a pilot of our platform over the summer. For the pilot, we’re going to be based out of New Delhi, with the aim of working with medium to small-sized publications across India.</p>
                <a className="continue-reading" href="https://medium.com/@tanayvardhan/building-turkbox-a-way-to-pay-for-news-by-training-ai-ed19ef1bb02e">Read full article on Medium →</a>
            </>)
        }
    },
    {
        "name": "Charo",
        "description": "Automating the process of looking up and translating lyrics of French rap songs using React and Redux",
        "thumbnail": charoThumbnail,
        "link": "/blog/charo",
        "post": {
            "title": "Charo",
            "coverUrl": charo1,
            "type": `Side Project`,
            "timeline": `June 2018 - August 2018 (3 months)`,
            "challenge": `Looking up and translating lyrics for songs can be tedious`,
            "solution": "A React app that automatically picks up playback information using the Spotify API, looks up lyrics from Genius, and displays the lyrics along with their translation",
            "awards": "",
            "links": (<>
                <ul>
                    <li><a href="https://charognard.herokuapp.com">Live Demo</a></li>
                    <li><a href="https://github.com/tanayv/charo">GitHub Repository</a></li>
                </ul>
            </>),
            "body": (<>
                <p>Last summer, I was interning at Amadeus, near Nice, France. I started listening to a lot of French rap and French music in general increased, from friends and the radio of the shuttle I took to work everyday. I also discovered some cool playlists curated on Spotify, such as Cloud Rap, and Rap FR. Even in the songs I had heard before, I could now understand words other than footballers‘ names thanks to my slightly improved French comprehension and vocabulary. I was now looking up song lyrics more often on Genius, and translating them using Google Translate. This usually turned out to be a pretty tedious process, especially if I was doing it on my phone.</p>
                <p>For a long time I had been meaning to use the Spotify Web API, but I never really got around to it because I could never come up with a good idea for a project to use it in. I had a relatively simple flow for the application in mind. I wanted to get information about the song I was listening to from the Spotify API. Then, I would look for this song on Genius, and get lyrics because I assumed that’s what the Genius API would be for. I would then pass these lyrics through some kind of translation API. Finally, I would just display everything in a relatively simple UI, allowing me to switch between the lyrics and translation.</p>
                <img src={charo2} alt={`A screenshot of how you log into the application with Spotify and open translations for the song you're playing`} />
                <p>In the future, even though I don’t see this application as a direct replacement for the Spotify app that I generally use, it would still be nice to have some form of playback control to pause, rewind or skip a song, or move to a different playlist (the Spotify API allows all of this!).</p>
                <a className="continue-reading" href="https://medium.com/@tanayvardhan/scaling-the-language-barrier-in-french-rap-with-react-and-redux-a9b3a9348f77">Read full article on Medium →</a>
            </>)
        }
    },
    {
        "name": "Digital Asia",
        "description": "An Angular powered CMS and website to help integrate Asian history and culture into high school curriculum",
        "thumbnail": digitalAsiaThumbnail,
        "link": "/blog/digital-asia",
        "post": {
            "title": "Digital Asia",
            "coverUrl": digitalAsia1,
            "type": `On-campus job`,
            "timeline": `January 2017 - April 2018`,
            "challenge": `How can we integrate documentaries on Asian culture and history into high school curriculum`,
            "solution": "An Angular powered CMS and website",
            "awards": "",
            "links": (<>
                <ul>
                    <li><a href="https://digitalasia.illinois.edu">Live Demo</a></li>
                </ul>
            </>),
            "body": (<>
                <p>I worked with the Center for Asian and Pacific Studies (CEAPS) as a web developer, and worked on Digital Asia. The objective was to build a portal that students can use to watch annotated documentaries on Asia. The first solution I worked on was a static angular application, that could be deployed in the serverless environment we were initially working with.</p>
                <p>After the first iteration was complete, the static website was developed and introduced to teachers from high schools around Urbana, Champaign, Tuscola, and Fisher in Illinois. Before the launch, we also held a "Digital Asia Usability Testing" session, where we asked teachers about how they anticipate students using the platform, and what improvements we could make to the platform. We gained valuable insight from the session, and learned that teachers are also looking for help with preparing for assignments, and would like to have a space on the website where they could access content related to the documentaries. </p>
                <img src={digitalAsia2} alt={`A screenshot of the Digital Asia dashboard`} />
                <p>With this new requirement, we began work on a dashboard for teachers. The onboarding process involved CEAPS staff giving access to teachers, at their verified school email IDs, which they could later on log in with. The dashboard contains resources such as supplemental presentations, video transcripts and curriculum guides, to assist teachers while they are covering documentaries. With the dashboard now functional, we extended its functionality by creating a section that allowed CEAPS staff to add and edit content, which was earlier not possible in the serverless environment.</p>
                <p>To accommodate the technical requirements of this content management system, I also developed a PHP backend, hosted on an Apache server available on the university's hosting environment. The Angular frontend now queries the PHP backend to access content and for all operations related to the dashboard. I also implemented a client-side restriction on content, as due to licensing agreements, the documentaries on the website could only be viewed from North America.  </p>
            </>)
        }
    }
];

export {
    longforms
}
import React from "react"
import "./../components/layout.css"

const LongformTemplate = (props) => {

  return (
      <div className="blog-post-container">
        <h1>{props.title}</h1>
        <a href="/">Home</a> > {props.title}
        <div className="cover" style={
          { backgroundImage: `url('${props.coverUrl}')` }
        }></div>

        <div className="overview-flex">
          <FlexCell title={`Type`} value={props.type}/>
          <FlexCell title={`Challenge`} value={props.challenge}/>
          <FlexCell title={`Timeline`} value={props.timeline}/>
          <FlexCell title={`Awards`} value={props.awards}/>
          <FlexCell title={`Solution`} value={props.solution}/>
          <FlexCell title={`Links`} value={props.links}/>
        </div>

        <div class="text-wrapper">
          {props.body}
        </div>
      </div>
  )
}

export default LongformTemplate;


const FlexCell = (props) => {
  if (props.value === "")
    return (<></>);

  else {
    return (
      <div className="cell">
        <div className="cell-container">
          <h2>{props.title}</h2>
        </div>
        <hr/>
        <div className="cell-container">
          {props.value}
        </div>
      </div>)
  }
}